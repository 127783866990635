const DocMenuConfig = [
  {
    heading: 'Manage',
    route: '/manage',
    pages: [
      {
        heading: 'dashboard',
        route: '/dashboard',
        svgIcon: 'media/icons/duotune/art/art002.svg',
        fontIcon: 'bi-app-indicator',
        ability: {
          method: 'read',
          resource: 'dashboard',
        },
      },
      {
        sectionTitle: 'Members',
        route: '/members',
        svgIcon: '/media/icons/duotune/technology/teh004.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: [
            'admins',
            'users',
            'official-appraisal',
            'roles',
            'official-appraisal',
            'own-appraiser',
          ],
        },
        sub: [
          {
            heading: 'Admins',
            route: '/manage/members/admins-listing',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Users',
            route: '/manage/members/users-listing',
            ability: {
              method: 'read',
              resource: 'users',
            },
          },
          {
            heading: 'Appraisers',
            route: '/manage/members/appraisers-listing',
            ability: {
              method: 'read',
              resource: ['official-appraisal', 'own-appraiser'],
            },
          },
          {
            heading: 'Roles & Permission',
            route: '/manage/members/roles-listing',
            ability: {
              method: 'read',
              resource: 'roles',
            },
          },
          {
            heading: 'Banks',
            route: '/manage/members/banks-listing',
            ability: {
              method: 'read',
              resource: 'official-appraisal',
            },
          },
        ],
      },
      {
        sectionTitle: 'Ads',
        route: '/ads',
        svgIcon: '/media/icons/duotune/coding/cod010.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'ads',
        },
        sub: [
          {
            heading: 'Ads List',
            route: '/manage/ads/ads-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
          {
            heading: 'Property Type',
            route: '/manage/ads/property-types-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
          {
            heading: 'Property Categories',
            route: '/manage/ads/property-categories-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
          {
            heading: 'Ad Types',
            route: '/manage/ads/ad-types-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
        ],
      },
      {
        sectionTitle: 'Official Appraisals',
        route: '/appraisals',
        svgIcon: '/media/icons/duotune/finance/fin007.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'official-appraisal',
        },
        sub: [
          {
            heading: 'Property Type',
            route: '/manage/appraisals/properties-listing',
            ability: {
              method: 'read',
              resource: 'official-appraisal',
            },
          },
          {
            heading: 'Appraisals Requests',
            route: '/manage/appraisals/appraisals-listing',
            ability: {
              method: 'read',
              resource: 'official-appraisal',
            },
          },
        ],
      },
      {
        sectionTitle: 'Official Appraisals',
        route: '/appraisals',
        svgIcon: '/media/icons/duotune/finance/fin007.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'appraisal-request',
        },
        sub: [
          {
            heading: 'Requests',
            route: '/manage/appraisals/appraiser-appraisals-listing',
            ability: {
              method: 'read',
              resource: 'appraisal-request',
            },
          },
        ],
      },
      {
        sectionTitle: 'Auctions',
        route: '/auctions',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'auctions',
        },
        sub: [
          {
            heading: 'Auction Events',
            route: '/manage/auctions/auctions-listing',
            ability: {
              method: 'read',
              resource: 'auctions',
            },
          },
          {
            heading: 'Property Types',
            route: '/manage/auctions/property-types-listing',
            ability: {
              method: 'read',
              resource: 'auctions',
            },
          },
          {
            heading: 'Organizers',
            route: '/manage/auctions/organizers-listing',
            ability: {
              method: 'read',
              resource: 'auctions',
            },
          },
          {
            heading: 'Organizers Locations',
            route: '/manage/auctions/locations-listing',
            ability: {
              method: 'read',
              resource: 'auctions',
            },
          },
        ],
      },
      {
        sectionTitle: 'IVE',
        route: '/ive',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'ive',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ive/area/areas-listing',
            ability: {
              method: 'read',
              resource: 'ive',
            },
          },
          {
            heading: 'Estimator',
            route: '/manage/ive/estimator/land-listing',
            ability: {
              method: 'read',
              resource: 'ive',
            },
          },
          {
            sectionTitle: 'Land Factor Options',
            route: '/manage/ive/factor-options',
            ability: {
              method: 'read',
              resource: 'ive',
            },
            sub: [
              {
                heading: 'Land Size',
                route: '/manage/ive/factor-options/land-size-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Street',
                route: '/manage/ive/factor-options/street-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Location',
                route: '/manage/ive/factor-options/location-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Location Specs',
                route: '/manage/ive/factor-options/location-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Facing Specs',
                route: '/manage/ive/factor-options/facing-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Curb',
                route: '/manage/ive/factor-options/curb-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Plot Specs',
                route: '/manage/ive/factor-options/plot-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Direction',
                route: '/manage/ive/factor-options/direction-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Sabah City',
                route: '/manage/ive/factor-options/Sabah-city-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
            ],
          },
          {
            sectionTitle: 'Building Factor Options',
            route: '/manage/ive/building-factor-options',
            ability: {
              method: 'read',
              resource: 'ive',
            },
            sub: [
              {
                heading: 'Building Age',
                route:
                  '/manage/ive/building-factor-options/building-age-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Number Of Floors',
                route: '/manage/ive/building-factor-options/floors-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Finishing Quality',
                route: '/manage/ive/building-factor-options/fq-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
            ],
          },
          {
            sectionTitle: 'Other',
            route: '/other',
            ability: {
              method: 'read',
              resource: 'ive',
            },
            sub: [
              {
                heading: 'Sea Front Lengths',
                route: '/manage/ive/other/sea-front-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Back Street Lengths',
                route: '/manage/ive/other/back-street-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
            ],
          },
          {
            sectionTitle: 'Finishing Quality',
            route: '/finishing-quality',
            ability: {
              method: 'read',
              resource: 'ive',
            },
            sub: [
              {
                heading: 'Quality Ranges',
                route:
                  '/manage/ive/finishing-quality/ranges/quality-ranges-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
              {
                heading: 'Quality Groups',
                route:
                  '/manage/ive/finishing-quality/groups/quality-groups-listing',
                ability: {
                  method: 'read',
                  resource: 'ive',
                },
              },
            ],
          },
        ],
      },
      {
        sectionTitle: 'IRE',
        route: '/ire-v1',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Apartment Type',
            route: '/manage/ire/apartment-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Apartment Size',
            route: '/manage/ire/sizes-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },

          {
            sectionTitle: 'Factors',
            route: '/ire-factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Bathrooms',
                route: '/manage/ire/factors-bathrooms-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Options',
                route: '/manage/ire/factors-options-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Quality',
                route: '/manage/ire/factors-qualitys-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'General specs',
                route: '/manage/ire/factors-general-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Property Services ',
                route: '/manage/ire/factors-property-services-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Location specs',
                route: '/manage/ire/factors-location-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },
      {
        sectionTitle: 'IRE V2',
        route: '/ire-v2',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire-v2/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            sectionTitle: 'Factors',
            route: '/ire-factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Property Type',
                route: '/manage/ire-v2/property-type-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Floor Type',
                route: '/manage/ire-v2/floor-type-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Property Age',
                route: '/manage/ire-v2/property-age-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },
      {
        sectionTitle: 'IRE Investment',
        route: '/manage/ire-investment',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire-investment/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'config',
            route: '/manage/ire-investment/config-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Type',
            route: '/manage/ire-investment/property-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Age',
            route: '/manage/ire-investment/property-age-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Locations',
            route: '/manage/ire-investment/locations-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            sectionTitle: 'Investment Factors',
            route: '/manage/ire-investment/factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Property Services',
                route:
                  '/manage/ire-investment/factors-property-services-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Location specs',
                route: '/manage/ire-investment/factors-location-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
              {
                heading: 'Electricity',
                route:
                  '/manage/ire-investment/factors-electricity-specs-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },

      {
        sectionTitle: 'IRE Literal',
        route: '/manage/ire-literal',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire-literal/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Streets',
            route: '/manage/ire-literal/street-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Type',
            route: '/manage/ire-literal/property-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Floor Type',
            route: '/manage/ire-literal/floor-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Direction',
            route: '/manage/ire-literal/direction-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Age',
            route: '/manage/ire-literal/property-age-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Quality',
            route: '/manage/ire-literal/quality-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            sectionTitle: 'literal Factors',
            route: '/manage/ire-literal/factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Property Services',
                route: '/manage/ire-literal/factors-property-services-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },
      {
        sectionTitle: 'IRE Commercial',
        route: '/manage/ire-commercial',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire-commercial/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Streets',
            route: '/manage/ire-commercial/street-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Type',
            route: '/manage/ire-commercial/property-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Floor Type',
            route: '/manage/ire-commercial/floor-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Direction',
            route: '/manage/ire-commercial/direction-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Age',
            route: '/manage/ire-commercial/property-age-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Quality',
            route: '/manage/ire-commercial/quality-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            sectionTitle: 'commercial Factors',
            route: '/manage/ire-commercial/factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Property Services',
                route:
                  '/manage/ire-commercial/factors-property-services-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },
      //99
      {
        sectionTitle: 'IRE Industrial',
        route: '/manage/ire-industrial',
        svgIcon: '/media/icons/duotune/finance/fin003.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'instant-rent-estimator',
        },
        sub: [
          {
            heading: 'Areas',
            route: '/manage/ire-industrial/areas-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Streets',
            route: '/manage/ire-industrial/street-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Type',
            route: '/manage/ire-industrial/property-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Floor Type',
            route: '/manage/ire-industrial/floor-type-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Direction',
            route: '/manage/ire-industrial/direction-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Property Age',
            route: '/manage/ire-industrial/property-age-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            heading: 'Quality',
            route: '/manage/ire-industrial/quality-listing',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
          },
          {
            sectionTitle: 'industrial Factors',
            route: '/manage/ire-industrial/factors',
            svgIcon: '/media/icons/duotune/technology/teh005.svg',
            fontIcon: 'bi-sticky',
            ability: {
              method: 'read',
              resource: 'instant-rent-estimator',
            },
            sub: [
              {
                heading: 'Property Services',
                route:
                  '/manage/ire-industrial/factors-property-services-listing',
                ability: {
                  method: 'read',
                  resource: 'instant-rent-estimator',
                },
              },
            ],
          },
        ],
      },
      {
        sectionTitle: 'CCE',
        route: '/cce',
        svgIcon: '/media/icons/duotune/technology/teh005.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'cce',
        },
        sub: [
          {
            heading: 'Groups',
            route: '/manage/cce/cce-groups-listing',
            ability: {
              method: 'read',
              resource: 'cce',
            },
          },
          {
            heading: 'Categories',
            route: '/manage/cce/cce-categories-listing',
            ability: {
              method: 'read',
              resource: 'cce',
            },
          },
          {
            heading: 'Sub Categories',
            route: '/manage/cce/cce-sub-categories-listing',
            ability: {
              method: 'read',
              resource: 'cce',
            },
          },
        ],
      },
      {
        sectionTitle: 'Assets',
        route: '/assets',
        svgIcon: '/media/icons/duotune/coding/cod010.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: ['assets', 'settings'],
        },
        sub: [
          {
            heading: 'Governance',
            route: '/manage/assets/gov-editing',
            ability: {
              method: 'read',
              resource: 'assets',
            },
          },
          {
            heading: 'Manage Area',
            route: '/manage/assets/areas-listing',
            ability: {
              method: 'read',
              resource: 'assets',
            },
          },
          {
            heading: 'Banners',
            route: '/manage/assets/banners-listing',
            ability: {
              method: 'read',
              resource: 'settings',
            },
          },
          {
            heading: 'Home Services',
            route: '/manage/assets/home-services-listing',
            ability: {
              method: 'read',
              resource: 'settings',
            },
          },
          {
            heading: 'News',
            route: '/manage/assets/news-listing',
            ability: {
              method: 'read',
              resource: 'assets',
            },
          },
        ],
      },
      {
        sectionTitle: 'RegisteredTrades',
        route: '/registered-trades',
        svgIcon: '/media/icons/duotune/general/gen055.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'registered-trades',
        },
        sub: [
          {
            heading: 'RegisteredTrades',
            route: '/manage/registered-trades/registered-trades-listing',
            ability: {
              method: 'read',
              resource: 'registered-trades',
            },
          },
          {
            heading: 'RegisteredTradesScraping',
            route:
              '/manage/registered-trades/registered-trades-scraping-listing',
            ability: {
              method: 'read',
              resource: 'registered-trades',
            },
          },
        ],
      },
      {
        sectionTitle: 'Loans Request',
        route: '/loans-request',
        svgIcon: '/media/icons/duotune/general/gen055.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'settings',
        },
        sub: [
          {
            heading: 'Loan Funds Request',
            route: '/manage/loan-request/loan-funds-request-listing',
            ability: {
              method: 'read',
              resource: 'loan-calculator',
            },
          },
          {
            heading: 'Finance Funds Request',
            route: '/manage/finance-request/finance-funds-request-listing',
            ability: {
              method: 'read',
              resource: 'loan-calculator',
            },
          },
        ],
      },
      {
        sectionTitle: 'Subscriptions',
        route: '/subscriptions',
        svgIcon: '/media/icons/duotune/general/gen055.svg',
        ability: {
          method: 'read',
          resource: 'ads',
        },
        fontIcon: 'bi-sticky',
        sub: [
          {
            heading: 'Ads Services',
            route: '/manage/subscriptions/ad-service-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
          {
            heading: 'Ads Packages',
            route: '/manage/subscriptions/ad-package-listing',
            ability: {
              method: 'read',
              resource: 'ads',
            },
          },
        ],
      },
      {
        sectionTitle: 'Banks',
        route: '/banks',
        svgIcon: '/media/icons/duotune/general/gen055.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'admin-banks-rates',
        },
        sub: [
          {
            heading: 'Bank Rates',
            route: '/manage/banks/admin-bank-rates-listing',
            ability: {
              method: 'read',
              resource: 'admin-banks-rates',
            },
          },
        ],
      },
      {
        sectionTitle: 'Reports',
        route: '/reports',
        svgIcon: '/media/icons/duotune/general/gen055.svg',
        fontIcon: 'bi-sticky',
        ability: {
          method: 'read',
          resource: 'reports',
        },
        sub: [
          {
            heading: 'Registered Trades Reports',
            route: '/manage/reports/reports-registered-trades',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Ads Quarter Reports',
            route: '/manage/reports/reports-ads-quarter',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Registered Trades Top Reports',
            route: '/manage/reports/top-reports-registered-trades',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Registered Trades Quarter Reports',
            route: '/manage/reports/reports-registered-trades-quarter',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Registered Trades Areas Reports',
            route: '/manage/reports/reports-registered-trades-areas',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
          {
            heading: 'Registered Trades Totals Reports TASR',
            route: '/manage/reports/reports-registered-trades-totals',
            ability: {
              method: 'read',
              resource: 'admins',
            },
          },
        ],
      },
      {
        sectionTitle: 'Banks',

        route: '/banks',

        svgIcon: '/media/icons/duotune/general/gen055.svg',

        fontIcon: 'bi-sticky',

        ability: {
          method: 'read',

          resource: 'banks-rates',
        },

        sub: [
          {
            heading: 'Bank Rates',

            route: '/manage/banks/bank-rates-listing',

            ability: {
              method: 'read',

              resource: 'banks-rates',
            },
          },
          {
            heading: 'Log Reports',
            route: '/manage/banks/bank-rates-log-reports',
            ability: {
              method: 'read',
              resource: 'banks-rates',
            },
          },
          {
            heading: 'Settings',
            route: '/manage/banks/bank-rates-setting',
            ability: {
              method: 'read',
              resource: 'banks-rates',
            },
          },
        ],
      },
    ],
  },

  {
    heading: 'Settings',
    route: '/settings',
    ability: {
      method: 'read',
      resource: 'settings',
    },
    pages: [
      {
        heading: 'About Us',
        route: '/manage/settings/about-us-overview',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
      {
        heading: 'Terms and Conditions',
        route: '/manage/settings/terms-conditions-overview',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
      {
        heading: 'FAQs',
        route: '/manage/settings/faqs-listing',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
      {
        heading: 'Contact Us',
        route: '/manage/settings/contact-us-listing',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
      {
        heading: 'Header',
        route: '/manage/settings/header-section-listing',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
      {
        heading: 'Disclaimer',
        route: '/manage/settings/disclaimer-listing',
        ability: {
          method: 'read',
          resource: 'settings',
        },
      },
    ],
  },
];

export default DocMenuConfig;
